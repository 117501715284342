const tripColumnsDefaultCheck = false;
const violationColumnsDefaultCheck = false;

export const mileageColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  },{
    id: 'date',
    name: 'Date',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'startKm',
    name: 'Start Km',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endKm',
    name: 'End Km',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'distance',
    name: 'Distance Covered',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'comment',
    name: 'Comments',
    checked: tripColumnsDefaultCheck
  },
];
export const tripColumnsCummulative = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  },
   {
    id: 'startTime',
    name: 'Start Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endTime',
    name: 'End Time',
    checked: tripColumnsDefaultCheck
  },  {
    id: 'totalDistance',
    name: 'Distance Covered',
    checked: tripColumnsDefaultCheck
  },
];

export const tripColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'priceConsumed',
    name: 'Price Consumed',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'startingCoordinates',
    name: 'Start Coordinates',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'startingLocation',
    name: 'Start Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endingCoordinates',
    name: 'End Coordinates',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endingLocation',
    name: 'End Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'startTime',
    name: 'Start Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endTime',
    name: 'End Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'duration',
    name: 'Duration',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'distance',
    name: 'Distance Covered',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fatigueCount',
    name: 'Fatigue Counts',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'idleTime',
    name: 'Idle Time',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'tripStartMeterReading',
    name: 'Start Meter Reading',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'tripEndMeterReading',
    name: 'End Meter Reading',
    checked: tripColumnsDefaultCheck
  },
  // {
  //     id: 'avg_speed',
  //     name: 'Average Speed',
  //     checked: tripColumnsDefaultCheck
  // },
  {
    id: 'violationCountForEachViolation',
    name: 'Violation count for each violation',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'geofenceEntryExitCount',
    name: 'Geofence entry exit count',
    checked: tripColumnsDefaultCheck
  }
];

export const vehicleHistoryColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'time',
    name: 'Date/Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'latlng',
    name: 'Latlng',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'ignition',
    name: 'Ignition',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'movement',
    name: 'Movement',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'speed',
    name: 'Speed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'externalvoltage',
    name: 'External_Voltage',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'idle',
    name: 'Idle',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'unplug',
    name: 'Unplug',
    checked: tripColumnsDefaultCheck
  },

];

export const geofenceTrackColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: tripColumnsDefaultCheck },
  { id: 'makeAndModel', name: 'Vehicle Make And Model', checked: tripColumnsDefaultCheck },
  { id: 'imei', name: 'imei', checked: tripColumnsDefaultCheck },
  { id: 'group', name: 'Group', checked: tripColumnsDefaultCheck },
  { id: 'sourceLocation', name: 'Source Location', checked: tripColumnsDefaultCheck },
  { id: 'sourceName', name: 'Start Location', checked: tripColumnsDefaultCheck },
  { id: 'leavingTime', name: 'Leaving Time', checked: tripColumnsDefaultCheck },
  { id: 'destinationLocation', name: 'Destination Location', checked: tripColumnsDefaultCheck },
  { id: 'destinationName', name: 'End Location', checked: tripColumnsDefaultCheck },
  { id: 'entryTime', name: 'Entry Time', checked: tripColumnsDefaultCheck },
  { id: 'sourceToDestinationTime', name: 'Duration', checked: tripColumnsDefaultCheck },
  { id: 'idleTime', name: 'Idle Time (MIN)', checked: tripColumnsDefaultCheck },
  { id: 'stoppageTime', name: 'Stoppage Time', checked: tripColumnsDefaultCheck },
  { id: 'HARSH_ACCELERATION', name: 'Harsh Accleration', checked: tripColumnsDefaultCheck },
  { id: 'HARSH_BRAKES', name: 'Sudden Brakes', checked: tripColumnsDefaultCheck },
  { id: 'HARSH_TURN', name: 'Sharp Turn', checked: tripColumnsDefaultCheck },
  { id: 'SPEED_LIMIT', name: 'Overspeeding', checked: tripColumnsDefaultCheck },
  { id: 'UNFASTEN_SEATBELT', name: 'Unfasten Seatbelt', checked: tripColumnsDefaultCheck },
  { id: 'voilations', name: 'Violation count', checked: tripColumnsDefaultCheck }];

export const vehiclesGeoFenceReportColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: tripColumnsDefaultCheck },
  { id: 'makeAndModel', name: 'Vehicle Make And Model', checked: tripColumnsDefaultCheck },
  { id: 'imei', name: 'imei', checked: tripColumnsDefaultCheck },
  { id: 'group', name: 'Group', checked: tripColumnsDefaultCheck },
  { id: 'geofence', name: 'Geofence', checked: tripColumnsDefaultCheck },
  { id: 'entryEnabled', name: 'Entry Enable', checked: tripColumnsDefaultCheck },
  { id: 'exitEnabled', name: 'Exit Enable', checked: tripColumnsDefaultCheck },
  { id: 'speedEnabled', name: 'Speed Enable', checked: tripColumnsDefaultCheck },
  { id: 'speed', name: 'Speed Limit', checked: tripColumnsDefaultCheck },
  ];


export const geoFenceTourReportColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: tripColumnsDefaultCheck },
  { id: 'group', name: 'Group', checked: tripColumnsDefaultCheck },
  { id: 'geofenceName', name: 'geofenceName', checked: tripColumnsDefaultCheck },
  { id: 'entryTime', name: 'Entry Time', checked: tripColumnsDefaultCheck },
  { id: 'exitTime', name: 'Exit Time', checked: tripColumnsDefaultCheck },
  { id: 'durationInFence', name: 'Duration', checked: tripColumnsDefaultCheck },
  { id: 'totalDistance', name: 'Distance', checked: tripColumnsDefaultCheck },
  { id: 'entrylatlng', name: 'Entry Location', checked: tripColumnsDefaultCheck },
  { id: 'exitlatlng', name: 'Exit location', checked: tripColumnsDefaultCheck },
  ];
export const vehiclesRouteFenceReportColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: tripColumnsDefaultCheck },
  { id: 'makeAndModel', name: 'Vehicle Make And Model', checked: tripColumnsDefaultCheck },
  { id: 'imei', name: 'imei', checked: tripColumnsDefaultCheck },
  { id: 'group', name: 'Group', checked: tripColumnsDefaultCheck },
  { id: 'routefence', name: 'Routefence', checked: tripColumnsDefaultCheck },
  { id: 'entryEnabled', name: 'Entry Enable', checked: tripColumnsDefaultCheck },
  { id: 'exitEnabled', name: 'Exit Enable', checked: tripColumnsDefaultCheck },
  { id: 'speedEnabled', name: 'Speed Enable', checked: tripColumnsDefaultCheck },
  { id: 'speed', name: 'Speed Limit', checked: tripColumnsDefaultCheck },
];
export const pjpReportCloumns = [
  { id: 'pjp', name: 'PJP Name', checked: tripColumnsDefaultCheck },
  { id: 'vehicle', name: 'Trike', checked: tripColumnsDefaultCheck },
  { id: 'group', name: 'Group', checked: tripColumnsDefaultCheck },
  { id: 'hotspot', name: 'Hotspot', checked: tripColumnsDefaultCheck },
  { id: 'eventType', name: 'Event Type', checked: tripColumnsDefaultCheck },
  { id: 'hotspotEntryTime', name: 'Hotspot Entry Time', checked: tripColumnsDefaultCheck },
  { id: 'hotspotExitTime', name: 'Hotspot Exit Time', checked: tripColumnsDefaultCheck },
  { id: 'eventTime', name: 'Entry/Exit Time', checked: tripColumnsDefaultCheck },
  { id: 'timeDiff', name: 'Difference', checked: tripColumnsDefaultCheck },
  { id: 'type', name: 'Entry/Exit Type', checked: tripColumnsDefaultCheck },
];


export const fuelAverageColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  }, 
  {
    id: 'group',
    name: 'Group',
    checked: tripColumnsDefaultCheck
  }, 
  {
    id: 'date',
    name: 'Date',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'startTime',
    name: 'Start Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endTime',
    name: 'End Time',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'startingLocation',
    name: 'Start Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endingLocation',
    name: 'End Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'duration',
    name: 'Duration',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'distance',
    name: 'Distance Covered',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelConsumed',
    name: 'Fuel Consumed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'idleTime',
    name: 'Idle Time',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'avg_speed',
    name: 'Average Speed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelAverage',
    name: 'Fuel Average',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelGroupName',
    name: 'Fuel Group Name',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'totalFuelConsumed',
    name: 'Total Fuel Consumed',
    checked: tripColumnsDefaultCheck
  },
];

export const newfuelAverageColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  }, 
  {
    id: 'group',
    name: 'Group',
    checked: tripColumnsDefaultCheck
  }, 
  {
    id: 'date',
    name: 'Date',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'startTime',
    name: 'Start Time',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endTime',
    name: 'End Time',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'startingLocation',
    name: 'Start Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'endingLocation',
    name: 'End Location',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'duration',
    name: 'Duration',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'distance',
    name: 'Distance Covered',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelConsumed',
    name: 'Fuel Consumed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'idleTime',
    name: 'Idle Time',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'avg_speed',
    name: 'Average Speed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelAverage',
    name: 'Fuel Average',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'fuelGroupName',
    name: 'Fuel Group Name',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'totalFuelConsumed',
    name: 'Total Fuel Consumed',
    checked: tripColumnsDefaultCheck
  },
];

export const violationColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  },
   {
    id: 'eventType',
    name: 'Violations type',
    checked: violationColumnsDefaultCheck
  }, 
   {
    id: 'speed',
    name: 'Speed',
    checked: violationColumnsDefaultCheck
  }, 
  {
    id: 'eventTime',
    name: 'Violation Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'violationLocation',
    name: 'Violation location',
    checked: violationColumnsDefaultCheck
  }
];
export const customViolationColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  },
   {
    id: 'eventType',
    name: 'Violations type',
    checked: violationColumnsDefaultCheck
  }, 
   {
    id: 'currentLimit',
    name: 'Current Limit',
    checked: violationColumnsDefaultCheck
  }, 
   {
    id: 'eventValue',
    name: 'Value',
    checked: violationColumnsDefaultCheck
  }, 
   {
    id: 'duration',
    name: 'Dauration',
    checked: violationColumnsDefaultCheck
  }, 
   {
    id: 'intensity',
    name: 'Intensity',
    checked: violationColumnsDefaultCheck
  }, 
  {
    id: 'eventTime',
    name: 'Violation Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'violationLocation',
    name: 'Violation location',
    checked: violationColumnsDefaultCheck
  }
];

export const geoFenceColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'geoFenceName',
    name: 'Geofence Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventType',
    name: 'Entry or Exit',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventTime',
    name: 'Time',
    checked: violationColumnsDefaultCheck
  }, 
  {
    id: 'location',
    name: 'GeoFence location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'speed',
    name: 'Speed',
    checked: violationColumnsDefaultCheck
  }

];

export const geoFenceSummaryColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }

];
export const geoFenceFuelReportColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'geoFenceName',
    name: 'Geofence Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'entryTime',
    name: 'Entry Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'entryFuelAvg',
    name: 'Entry Fuel Value',
    checked: violationColumnsDefaultCheck
  }, 
  {
    id: 'exitTime',
    name: 'Exit Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'exitFuelAvg',
    name: 'Exit Fuel Avg',
    checked: violationColumnsDefaultCheck
  }

];

export const routeFenceColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'routeFenceName',
    name: 'Routefence Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventType',
    name: 'Entry or Exit',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventTime',
    name: 'Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'location',
    name: ' RouteFence location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'speed',
    name: 'Speed',
    checked: violationColumnsDefaultCheck
  }

];

export const alarmColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'alarmName',
    name: ' Alarm Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventType',
    name: ' Alarm Type',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventTime',
    name: 'Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'location',
    name: 'Alarm location',
    checked: violationColumnsDefaultCheck
  }
];
export const helmetStatusColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'alarmName',
    name: 'Status Name',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventType',
    name: 'Type',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'eventTime',
    name: 'Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'location',
    name: 'Location',
    checked: violationColumnsDefaultCheck
  }

];

export const idleColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'duration',
    name: ' Duration',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'startTime',
    name: 'Start Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'endTime',
    name: 'End Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'location',
    name: 'Location',
    checked: violationColumnsDefaultCheck
  }

];

export const alarmTypesCollection = [
  {
    id: 'IGNITION_ON',
    name: 'Ignition',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'IGNITION_OFF',
    name: 'Vehicle Parked',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'DEVICE_UNPLUG',
    name: 'Device plug out',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'DEVICE_PLUG_IN',
    name: 'Device plug in',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'vibration',
    name: 'Vibration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'IMPACT',
    name: 'Impact',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'VEHICLE_MOVING',
    name: 'Moving',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'IDLE',
    name: 'Idle',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'LOW_FUEL',
    name: 'Low Fuel',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HIGH_RPM',
    name: 'High RPM',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'ENGINE_HEATUP',
    name: 'Engine Heatup',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'DOOR_STATUS',
    name: 'Door Status',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'PANIC_BUTTON',
    name: 'Panic',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'JAMMING',
    name: 'Jammer detected',
    checked: violationColumnsDefaultCheck
  },
];
export const helmetStatusTypesCollection = [
  {
    id: 'HELMET_PARKED',
    name: 'Parked',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HELMET_WEARING',
    name: 'Helmet Wearing',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HELMET_WEARING_DRIVING',
    name: 'Helmet Wearing and Driving',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HELMET_NOT_WEARING_DRIVING',
    name: 'Helmet Not Wearing and Driving',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HELMET_IDLE',
    name: 'Idle',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HELMET_ROLLOVER',
    name: 'Helmet Rollover',
    checked: violationColumnsDefaultCheck
  }

];

export const stopageReportColumns = [
  {
    id: 'type',
    name: 'Type',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'date',
    name: 'Date',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make And Model',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverName',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'thingName',
    name: 'Thing Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverSerialNumber',
    name: 'Driver Serial Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startingLocation',
    name: 'Starting Location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endingLocation',
    name: 'Ending Location',
    checked: violationColumnsDefaultCheck
  },

  {
    id: 'stoppageLocation',
    name: 'Stoppage Location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'stoppageCoordinates',
    name: 'Stoppage Coordinates',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startTime',
    name: 'Start Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endTime',
    name: 'End Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'stopDuration',
    name: 'Duration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'distance',
    name: 'Distance Covered',
    checked: violationColumnsDefaultCheck
  },

];

export const vehicleTrackingReportColumns = [
  {
    id: 'serial',
    name: 'Sr.',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group3thLevel',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group4thLevel',
    name: 'Sub-Group 2',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group5thLevel',
    name: 'Sub-Group 3',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group6thLevel',
    name: 'Sub-Group 4',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'partyName',
    name: 'Party Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'partyId',
    name: 'Party ID',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'chasisNumber',
    name: 'Chasis No.',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'partyTerritory',
    name: 'Party Territory',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'Registration #=Tracker Id',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'phone',
    name: 'MSISDN',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'distance',
    name: 'Distance KM',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'runningTime',
    name: 'Running Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'tripCount',
    name: 'Number of trips',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'stoppageDuration',
    name: 'Stop time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'dayIdle',
    name: 'Idle time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'idlCount',
    name: 'Idle Count',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'stoppageCount',
    name: 'Stoppage Count',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'averageSpeed',
    name: 'Average Speed',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startTime',
    name: 'Start Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endTime',
    name: 'End Time',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startLocation',
    name: 'Start Geo location(Address)',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endLocation',
    name: 'End Geo location(address)',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'lastHeardDuration',
    name: 'Tracker not responded',
    checked: violationColumnsDefaultCheck
  },

];

export const byceTrackingReportColumns = [
  {
    id: 'serial',
    name: 'Sr.',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'Vehicle Registration No',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'zone',
    name: 'Zones',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'department',
    name: 'Department',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driver',
    name: 'User Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'enginepower',
    name: 'Engine Power',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'maxspeed',
    name: 'Max Speed',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'speedviolationcount',
    name: 'Sv Normal Route',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'harshbrakecount',
    name: 'Harsh Braking',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'vehiclestatus',
    name: 'Reporting Status',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'distance',
    name: 'Distance Covered KM',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'averageSpeed',
    name: 'Average Speed',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'driverscore',
    name: 'Driver Score',
    checked: violationColumnsDefaultCheck
  }
];

export const dliReportColumns = [
  {
    id: 'serial',
    name: 'Vehicle Reg No',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'category',
    name: 'Category',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'region',
    name: 'Region',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'cluster',
    name: 'Cluster',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'ibc',
    name: 'IBC / VIBC /Department',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'shifthrs',
    name: 'Shift (Hrs)',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'shifttiming',
    name: 'Shift Timing',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'benchmark',
    name: 'BenchMark (Km)',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'accinon',
    name: 'Accumulated in On',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'accinoff',
    name: 'Accumulated in Off',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'accsum',
    name: 'Accumulated Sum',
    checked: violationColumnsDefaultCheck
  },
];

export const oilChangeReportColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: violationColumnsDefaultCheck, },
  { id: 'imei', name: 'imei', checked: violationColumnsDefaultCheck, },
  { id: 'group', name: 'group', checked: violationColumnsDefaultCheck, },
  { id: 'currentMileage', name: 'Current Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'lastChangeDate', name: 'Last Change Date', checked: violationColumnsDefaultCheck, },
  { id: 'expiryMilage', name: 'Expiry Distance', checked: violationColumnsDefaultCheck, },
  { id: 'lastChangeMilage', name: 'Last Change Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'expiryDuration', name: 'Expiry Duration', checked: violationColumnsDefaultCheck, },
  {
    id: 'durationCoveredSinceLastChange',
    name: 'Duration Covered Since Last Change',
    checked: violationColumnsDefaultCheck,
  },
  {
    id: 'distanceCoveredSinceLastChange',
    name: 'Distance Covered Since Last Change',
    checked: violationColumnsDefaultCheck,
  },
  { id: 'changeDueDate', name: 'Change Due Date', checked: violationColumnsDefaultCheck, },
  { id: 'changeDueDistance', name: 'Change Due Distance', checked: violationColumnsDefaultCheck, },
  { id: 'changeAlert', name: 'Change Alert', checked: violationColumnsDefaultCheck, },
  { id: 'changeAlertMilage', name: 'Change Alert Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'distanceLeftForChange', name: 'Distance Left For Change', checked: violationColumnsDefaultCheck, },
  { id: 'DurationLeftForChange', name: 'Duration Left For Change', checked: violationColumnsDefaultCheck, },
  { id: 'remarks', name: 'Remarks', checked: violationColumnsDefaultCheck, }

];

export const tyreChangeReportColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: violationColumnsDefaultCheck, },
  { id: 'imei', name: 'imei', checked: violationColumnsDefaultCheck, },
  { id: 'group', name: 'group', checked: violationColumnsDefaultCheck, },
  { id: 'currentMileage', name: 'Current Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'lastChangeDate', name: 'Last Change Date', checked: violationColumnsDefaultCheck, },
  { id: 'expiryMilage', name: 'Expiry Distance', checked: violationColumnsDefaultCheck, },
  { id: 'lastChangeMilage', name: 'Last Change Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'expiryDuration', name: 'Expiry Duration', checked: violationColumnsDefaultCheck, },
  {
    id: 'durationCoveredSinceLastChange',
    name: 'Duration Covered Since Last Change',
    checked: violationColumnsDefaultCheck,
  },
  {
    id: 'distanceCoveredSinceLastChange',
    name: 'Distance Covered Since Last Change',
    checked: violationColumnsDefaultCheck,
  },
  { id: 'changeDueDate', name: 'Change Due Date', checked: violationColumnsDefaultCheck, },
  { id: 'changeDueDistance', name: 'Change Due Distance', checked: violationColumnsDefaultCheck, },
  { id: 'changeAlert', name: 'Change Alert', checked: violationColumnsDefaultCheck, },
  { id: 'changeAlertMilage', name: 'Change Alert Mileage', checked: violationColumnsDefaultCheck, },
  { id: 'distanceLeftForChange', name: 'Distance Left For Change', checked: violationColumnsDefaultCheck, },
  { id: 'DurationLeftForChange', name: 'Duration Left For Change', checked: violationColumnsDefaultCheck, },
  { id: 'remarks', name: 'Remarks', checked: violationColumnsDefaultCheck, }

];
export const otherMaintainanceColumns = [
  { id: 'plateNumber', name: 'Plate Number', checked: violationColumnsDefaultCheck, },
  { id: 'imei', name: 'imei', checked: violationColumnsDefaultCheck, },
  { id: 'group', name: 'group', checked: violationColumnsDefaultCheck, },
  { id: 'otherMaintenanceType', name: 'Type', checked: violationColumnsDefaultCheck, },
  { id: 'description', name: 'Description', checked: violationColumnsDefaultCheck, },
  { id: 'maintenanceDate', name: 'Date', checked: violationColumnsDefaultCheck, },
  { id: 'cost', name: 'Cost', checked: violationColumnsDefaultCheck, },
  { id: 'warrenty', name: 'Warrenty', checked: violationColumnsDefaultCheck, },
  { id: 'remarks', name: 'Remarks', checked: violationColumnsDefaultCheck, },
  
  
];

export const drivingScoreColumns = [
  {
    id: 'serialNUMBER',
    name: 'Serial Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'name',
    name: 'Driver Name',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'PlateNumber',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'drivingScore',
    name: 'Driving Score',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'vehicledrivingScore',
    name: 'Vehicle Driving Score',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_ACCELERATION',
    name: 'Harsh Acceleration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_BRAKES',
    name: 'Sudden Brake',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'SPEED_LIMIT',
    name: 'Overspeeding',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_TURN',
    name: 'Sharp Turn',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'UNFASTEN_SEATBELT',
    name: 'Unfasten Seatbelt',
    checked: violationColumnsDefaultCheck
  },

];

export const vehicleDrivingScoreColumns = [
  {
    id: 'plateNumber',
    name: 'PlateNumber',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'groupName',
    name: 'groupName',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'drivingScore',
    name: 'Driving Score',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_ACCELERATION',
    name: 'Harsh Acceleration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_BRAKES',
    name: 'Sudden Brake',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'SPEED_LIMIT',
    name: 'Overspeeding',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_TURN',
    name: 'Sharp Turn',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'UNFASTEN_SEATBELT',
    name: 'Unfasten Seatbelt',
    checked: violationColumnsDefaultCheck
  },

];

export const overspeedingColumns = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  },
   {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'time',
    name: 'Time',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'speed',
    name: 'Speed',
    checked: violationColumnsDefaultCheck
  }, {
    id: 'latlng',
    name: 'Location',
    checked: violationColumnsDefaultCheck
  }
];

export const distanceTraveledCollection = [
  {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'vehicleMakeAndModel',
    name: 'Make And Model',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'totalDistance',
    name: 'Distance',
    checked: violationColumnsDefaultCheck
  },

];


export const eventTypeColumnList = [
  {
    id: 'HARSH_ACCELERATION',
    name: 'Harsh acceleration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_BRAKES',
    name: 'Sudden brake',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_TURN',
    name: 'Sharp turn',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'SPEED_LIMIT',
    name: 'Overspeeding',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'UNFASTEN_SEATBELT',
    name: 'Unfasten seatbelt',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'FATIGUE',
    name: 'Fatigue',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'REST_AFTER_FATIGUE',
    name: 'Rest Fatigue',
    checked: violationColumnsDefaultCheck
  }
];
export const violationTypeColumnList = [
  {
    id: 'HARSH_ACCELERATION',
    name: 'Harsh acceleration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_BRAKES',
    name: 'Sudden brake',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_TURN',
    name: 'Sharp turn',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'SPEED_LIMIT',
    name: 'Overspeeding',
    checked: violationColumnsDefaultCheck
  },
];

export const vehicleTravelColumns = [
  {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'group',
    name: 'Group',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'imei',
    name: 'imei',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'Distance',
    name: 'distance',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startLocation',
    name: 'Start Location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endLocation',
    name: 'End Location',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'startAddress',
    name: 'Start Address',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'endAddress',
    name: 'End Address',
    checked: violationColumnsDefaultCheck
  },
  
];

export const performanceReportColumnList = [
  {
    id: 'plateNumber',
    name: 'Plate Number',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'vehicleMakeAndModel',
    name: 'Vehicle Make and Model',
    checked: tripColumnsDefaultCheck
  }, 
   {
    id: 'group',
    name: 'Group',
    checked: tripColumnsDefaultCheck
  }, 
  {
    id: 'imei',
    name: 'imei',
    checked: tripColumnsDefaultCheck
  
  }, 
  {
    id: 'vehicleStatus',
    name: 'Vehicle Status',
    checked: tripColumnsDefaultCheck
  
  }, 
  {
    id: 'totalDistance',
    name: 'Total Distance',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'idleTimes',
    name: 'Idle Times',
    checked: tripColumnsDefaultCheck
  }, {
    id: 'maxSpeed',
    name: 'Max Speed',
    checked: tripColumnsDefaultCheck
  },
  {
    id: 'HARSH_ACCELERATION',
    name: 'Harsh acceleration',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_BRAKES',
    name: 'Sudden brake',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'HARSH_TURN',
    name: 'Sharp turn',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'SPEED_LIMIT',
    name: 'Overspeeding',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'UNFASTEN_SEATBELT',
    name: 'Unfasten seatbelt',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'FATIGUE',
    name: 'Fatigue',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'score',
    name: 'Driving Score',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'geoFenceSpeedCount',
    name: 'GeoFence SpeedCount',
    checked: violationColumnsDefaultCheck
  },
  {
    id: 'routeFenceSpeedCount',
    name: 'RouteFence SpeedCount',
    checked: violationColumnsDefaultCheck
  },
 
];
