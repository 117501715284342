import React from 'react';
import { Route, Switch } from 'react-router';
import { ErrorBoundary } from './components/errorHandler';
import Layout from './components/layout/Layout';
import APPLogin from './components/login/Login';
import requireAuth from './utils/requireAuth';
import ForgotPassword from './components/login/ForgotPassword';
import ConfirmCode from './components/login/ConfirmCode';
import UserReset from './components/login/UserReset';
import VehicleAssignmentModal from './components/things/thingsAssignment/index';
import MapView from './components/vehicles/mapView/Index';
import MapToShare from './components/vehicles/mapToShare/Index';
import ChartData from './components/vehicles/chartData/index';
import Temperature from './components/vehicles/temperature/index';
import UniliverDashboard from './components/vehicles/mapView/uniliverDashboard';
import VehicleListing from './components/vehicles/mapView/listing';
import ViolationListView from './components/vehicles/mapView/violationListing';

import VehicleListView from './components/vehicles/listView/index';
import VehicleDetailView from './components/vehicles/listView/vehicleDetails';
import VehicleTrips from './components/vehicles/trips/index';
import TrackPlayback from './components/trackPlayback/index';
import GroupAccount from './components/settings/account/index';
import UpdateGroup from './components/settings/updateGroup/index';
import UserList from './components/user/UserList';
import AboutUS from './components/aboutUs/about';
import UserCreate from './components/user/UserCreate';
import UpdateUser from './components/user/UpdateUser';
import RoleList from './components/role/RoleList';
import RoleCreate from './components/role/RoleCreate';
import RoleUpdate from './components/role/RoleUpdate';
import DriversListView from './components/drivers/list/index';
import CreateDriver from './components/drivers/create/index';
import CreateComplaint from './components/complaint/create/index';
import ComplaintsListView from './components/complaint/list/index';
import EditDriver from './components/drivers/edit/index';
import GroupsListView from './components/group/list/index';
import GroupCreate from './components/group/create/index';
import GroupUpdate from './components/group/update/index';
import CreateGeoFence from './components/geoFence/create/index';
import GeoFenceListing from './components/geoFence/list/index';
import UpdateGeoFence from './components/geoFence/update/index';

import CreateHotspot from './components/hotspot/create/index';
import HotspotListing from './components/hotspot/list/index';
import UpdateHotspot from './components/hotspot/update/index';

import ReportsList from './components/reports/list';
import ReportInterval from './components/reports/interval';
import NotificationsList from './components/notifications/list';
import ScheduleListView from './components/schedule/list';
import ScheduleSlots from './components/schedule/slots';
import AddSchedule from './components/schedule/addSchedule';
import AddSlot from './components/schedule/addSlot';
import DriverDetails from './components/drivers/detail/index';
import AdminLogin from './components/adminLogin';
import FuelListView from './components/fuel';
import ReportScheduleView from './components/report-schedule';
import ErrorHandler from './components/common/ErrorHandler';
import ObservationPage from './components/Observation';
import RouterObservationPage from './components/routerObservations';
import ChillerObservationPage from './components/chiller';
import ECUObservationPage from './components/EcuObservation';
import RouteFenceListing from './components/routefence/list/index';
import CreateRouteFence from './components/routefence/create/index';
import UpdateRouteFence from './components/routefence/update/index';
import HelpComponent from './components/help/index';
import CellSiteListing from './components/Patrolling/cellSites/list';
import CreateCellSite from './components/Patrolling/cellSites/create';
import UpdateCellSite from './components/Patrolling/cellSites/update';
import PatrollingDashboard from './components/Patrolling/dashboard/index';
import PlayBack from './components/video/PlayBack';
import Live from './components/video/Live' 
// import PatrollingRegionListing from './components/Patrolling/regions/list';
// import CreateRegion from './components/Patrolling/regions/create';
// import UpdateRegion from './components/Patrolling/regions/update';
import PatrollingReportsList from './components/Patrolling/reports/list';
import RosterListing from './components/Patrolling/roster/list';
import CreateRoster from './components/Patrolling/roster/create';
import UpdateRoster from './components/Patrolling/roster/update';

import CreatePJP from './components/pjp/create/index';
import UpdatePJP from './components/pjp/update/index';
import PJPListing from './components/pjp/list/index';

import ViolationBarChart from './components/Visualization/ViolationBarChart';
import TripsBarChart from './components/Visualization/TripsBarChart';
import GdThingBarChart from './components/Visualization/GdThingBarChart';
import FuelBarChart from "./components/Visualization/FuelBarChart";
import FuelBarChartNew from "./components/Visualization/FuelBarChartNew";
import DownloadReports from "./components/reports/DownloadReports";
import vehicleScore from '../src/components/vehicles/listView/vehicleScore';
import GeofenceSummaryReport from './components/viewReports/geofenceSummaryReport';
import MileageReport from './components/viewReports/mileageReport';
import NewFuelSummaryReport from './components/viewReports/newFuelSummaryReport';
import NewFuelActivityReport from './components/viewReports/newFuelActivityReport';

import RagScore from './components/batragscore/index'
import CreateTelcoSite from './components/TelcoSites/create/index'
import CardinalViolations from './components/BAT-CARDINAL-VIOLATIONS/index'
import TelcoList from './components/TelcoSites/list';


import TrikesSummary from './components/trikes/summary';
import TrikesPlayback from './components/trikes/playback'



const requirePermission = (require, permissionString) => {
  return { authorize: require, permissionString, loaded: false };
};
const requireFeature = (require, permissionString) => {
  return { authorize: require, permissionString, loaded: false };
};

export default () => (
  <Switch>

    <Route path='/login' component={APPLogin} />
    <Route path='/forgot-password' component={ForgotPassword} />
    <Route path='/confirm-code/:userName' component={ConfirmCode} />
    <Route path='/user/activate' component={UserReset} />
    <Route path='/user/reset' component={UserReset} />
    <Route path='/adminLogin' component={AdminLogin} />
    <Route path='/error' component={ErrorHandler} />
    <Route path='/guest/mapShare/:sharedId' component={MapToShare} />
    <Layout>
      <ErrorBoundary>
        <Route exact path='/' component={requireAuth(MapView, requirePermission(true, 'DASHBOARD'), requireFeature(true, "DASHBOARD:VIEWS"))} />
        <Route exact path='/RAGScore' component={requireAuth(RagScore, requirePermission(true, 'DASHBOARD'), requireFeature(true, "DASHBOARD:VIEWS"))} />
        <Route exact path='/CardinalViolations' component={requireAuth(CardinalViolations, requirePermission(true, 'DASHBOARD'), requireFeature(true, "DASHBOARD:VIEWS"))} />
        <Route exact path='/router' component={requireAuth(ChartData, requirePermission(true, 'DASHBOARD'), requireFeature(true, "ROUTER"))} />
        <Route exact path='/temperature' component={requireAuth(Temperature, requirePermission(true, 'DASHBOARD'), requireFeature(true, "TEMPERATURE"))} />
        <Route exact path='/dashboard'
          component={requireAuth(UniliverDashboard,  requirePermission(true, 'DASHBOARD'), requireFeature(true, "HOTSPOT"))} />
        <Route
          exact
          path='/vehicle/assignment'
          component={requireAuth(VehicleAssignmentModal, requirePermission(true, 'UPDATE:VEHICLE'))} />

        <Route exact path='/listing'
          component={requireAuth(VehicleListing, false, requireFeature(true, "HOTSPOT"))} />
        <Route exact path='/violations'
          component={requireAuth(ViolationListView, false, requireFeature(true, "HOTSPOT"))} />
        <Route exact path='/vehicles/list'
          component={requireAuth(VehicleListView, requirePermission(true, 'VIEW:VEHICLE'))} />
       <Route exact path='/complaints/add'
          component={requireAuth(CreateComplaint, requirePermission(true, 'COMPLAINT'))} />
        <Route exact path='/complaints'
          component={requireAuth(ComplaintsListView, requirePermission(true, 'COMPLAINT'))} />
          <Route exact path='/vehicles/scorecard/:id/:plateNumber'
          component={requireAuth(vehicleScore, requirePermission(true, 'MAINTENANCE'), requireFeature(true, "MAINTENANCE"))} />
        <Route exact path='/vehicles/details/:id'
          component={requireAuth(VehicleDetailView, requirePermission(true, 'MAINTENANCE'), requireFeature(true, "MAINTENANCE"))} />
        <Route exact path='/vehicles/trips' component={requireAuth(VehicleTrips, requirePermission(true, 'TRIPS'), requireFeature(true, "TRIP:HISTORY"))} />
        <Route exact path='/vehicles/track-playback' component={requireAuth(TrackPlayback, requirePermission(true, 'PLAYBACK'), requireFeature(true, "PLAYBACK"))} />
        <Route path='/vehicles/observation' component={requireAuth(ObservationPage, requirePermission(true, 'OBSERVATIONS'), requireFeature(true, "OBSERVATIONS"))} />
        <Route path='/router/observation' component={requireAuth(RouterObservationPage)} />
        <Route path='/vehicles/chiller-observation' component={requireAuth(ChillerObservationPage, requirePermission(true, 'OBSERVATIONS'), requireFeature(true, "OBSERVATIONS"))} />
        <Route path='/vehicles/ecu-observation' component={requireAuth(ECUObservationPage, requirePermission(true, 'OBSERVATIONS'), requireFeature(true, "OBSERVATIONS"))} />
        <Route exact path='/account' component={requireAuth(GroupAccount)} />
        <Route exact path='/account/update/:id' component={requireAuth(UpdateGroup)} />
        <Route exact path='/aboutus' component={requireAuth(AboutUS)} />
        <Route exact path='/users' component={requireAuth(UserList, requirePermission(true, 'LIST:USER'))} />
        <Route exact path='/users/create/:id'
          component={requireAuth(UserCreate, requirePermission(true, 'CREATE:USER'))} />
        <Route exact path='/user/update/:id' component={requireAuth(UpdateUser)} />
        <Route exact path='/roles' component={requireAuth(RoleList, requirePermission(true, 'LIST:ROLE'))} />
        <Route exact path='/roles/create' component={requireAuth(RoleCreate, requirePermission(true, 'CREATE:ROLE'))} />
        <Route exact path='/role/update/:id'
          component={requireAuth(RoleUpdate, requirePermission(true, 'UPDATE:ROLE'))} />
        <Route exact path='/drivers' component={requireAuth(DriversListView, requirePermission(true, 'VIEW:DRIVER'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/drivers/detail/:id'
          component={requireAuth(DriverDetails, requirePermission(true, 'VIEW:DRIVER'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/drivers/add'
          component={requireAuth(CreateDriver, requirePermission(true, 'CREATE:DRIVER'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/drivers/edit'
          component={requireAuth(EditDriver, requirePermission(true, 'UPDATE:DRIVER'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/groups' component={requireAuth(GroupsListView, requirePermission(true, 'VIEW:GROUP'), requireFeature(true, "GROUPS"))} />
        <Route exact path='/groups/add' component={requireAuth(GroupCreate, requirePermission(true, 'CREATE:GROUP'), requireFeature(true, "GROUPS"))} />
        <Route exact path='/groups/:id/update'
          component={requireAuth(GroupUpdate, requirePermission(true, 'UPDATE:GROUP'), requireFeature(true, "GROUPS"))} />
        <Route exact path='/geofence'
          component={requireAuth(CreateGeoFence, requirePermission(true, 'CREATE:GEOFENCE'), requireFeature(true, "GEO-FENCE"))} />
        <Route exact path='/geofence/list'
          component={requireAuth(GeoFenceListing, requirePermission(true, 'VIEW:GEOFENCE'), requireFeature(true, "GEO-FENCE"))} />
          <Route exact path='/TelcoSites'
          component={requireAuth(TelcoList, requirePermission(true, 'VIEW:GEOFENCE'), requireFeature(true, "GEO-FENCE"))} />
          <Route exact path='/addTelcoSite'
          component={requireAuth(CreateTelcoSite, requirePermission(true, 'CREATE:GEOFENCE'), requireFeature(true, "GEO-FENCE"))} />
        <Route exact path='/geofence/update/:id'
          component={requireAuth(UpdateGeoFence, requirePermission(true, 'UPDATE:GEOFENCE'), requireFeature(true, "GEO-FENCE"))} />
       
        <Route exact path='/hotspot'
          component={requireAuth(CreateHotspot, false, requireFeature(true, "HOTSPOT"))} />

        
        <Route exact path='/hotspot/list'
          component={requireAuth(HotspotListing, false, requireFeature(true, "HOTSPOT"))} />
        <Route exact path='/hotspot/update/:id'
          component={requireAuth(UpdateHotspot, false, requireFeature(true, "HOTSPOT"))} />

        <Route exact path='/pjp/create'
          component={requireAuth(CreatePJP, false, requireFeature(true, "HOTSPOT"))} />
       <Route exact path='/pjp/update/:id'
          component={requireAuth(UpdatePJP, false, requireFeature(true, "HOTSPOT"))} />

        <Route exact path='/pjp'
          component={requireAuth(PJPListing, false, requireFeature(true, "HOTSPOT"))} />

        <Route exact path='/reports' component={requireAuth(ReportsList, requirePermission(true, 'REPORTS'), requireFeature(true, "REPORTS"))} />
        <Route exact path='/viewReports/geofenceSummaryReport' component={requireAuth(GeofenceSummaryReport, requirePermission(true, 'REPORTS'), requireFeature(true, "REPORTS"))} />
        <Route exact path='/viewReports/mileageReport' component={requireAuth(MileageReport, requirePermission(true, 'REPORTS'), requireFeature(true, "REPORTS"))} />
        <Route exact path='/viewReports/newFuelSummary' component={requireAuth(NewFuelSummaryReport, requirePermission(true, 'REPORTS'), requireFeature(true, "REPORTS"))} />
        <Route exact path='/viewReports/newFuelActivity' component={requireAuth(NewFuelActivityReport, requirePermission(true, 'REPORTS'), requireFeature(true, "REPORTS"))} />
        <Route exact path='/reports/geofence/:id' component={requireAuth(ReportsList, false, requireFeature(true, "REPORTS"))} />
        <Route exact path='/reports/:type' component={requireAuth(ReportInterval, false, requireFeature(true, "REPORTS"))} />
        <Route exact path='/notifications' component={requireAuth(NotificationsList, requirePermission(true, 'VIEW:NOTIFICATION'), requireFeature(true, "NOTIFICATIONS:INAPP"))} />
        <Route exact path='/schedule' component={requireAuth(ScheduleListView, requirePermission(true, 'LIST:SCHEDULE'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/schedule/add' component={requireAuth(AddSchedule, requirePermission(true, 'CREATE:SCHEDULE'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/schedule/details/:id' component={requireAuth(ScheduleSlots, requirePermission(true, 'LIST:SCHEDULE'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/slot/add/:scheduleId' component={requireAuth(AddSlot, requirePermission(true, 'UPDATE:SCHEDULE'), requireFeature(true, "DRIVER:MANAGEMENT"))} />
        <Route exact path='/fuel-group' component={requireAuth(FuelListView, requirePermission(true, 'FUEL_GROUP'), requireFeature(true, "FUEL:GROUP"))} />
        <Route exact path='/help' component={requireAuth(HelpComponent, false)} />

        <Route exact path='/report-schedule' component={requireAuth(ReportScheduleView, false, requireFeature(true, "REPORT:SCHEDULE"))} />
        <Route exact path='/routefence/list' component={requireAuth(RouteFenceListing, requirePermission(true, 'VIEW:ROUTEFENCE'), requireFeature(true, "ROUTE-FENCE"))} />
        <Route exact path='/routefence/create' component={requireAuth(CreateRouteFence, requirePermission(true, 'CREATE:ROUTEFENCE'), requireFeature(true, "ROUTE-FENCE"))} />
        <Route exact path='/routefence/update/:id'
          component={requireAuth(UpdateRouteFence, requirePermission(true, 'UPDATE:ROUTEFENCE'), requireFeature(true, "ROUTE-FENCE"))} />

        <Route exact path='/patrolling/cellsites'
          component={requireAuth(CellSiteListing, requirePermission(true, 'PATROLLING'), requireFeature(true, "PATROLLING"))} />
        <Route exact path='/patrolling/cellsite/create'
          component={requireAuth(CreateCellSite, requirePermission(true, 'PATROLLING'), requireFeature(true, "PATROLLING"))} />
        <Route exact path='/patrolling/cellsite/:id/update'
          component={requireAuth(UpdateCellSite, requirePermission(true, 'PATROLLING'), requireFeature(true, "PATROLLING"))} />
        <Route exact path='/patrolling/dashboard'
          component={requireAuth(PatrollingDashboard, requirePermission(true, 'PATROLLING'), requireFeature(true, "PATROLLING"))} />
        <Route exact path='/patrolling/reports'
          component={requireAuth(PatrollingReportsList, requirePermission(true, 'PATROLLING'), requireFeature(true, "PATROLLING"))} />
        <Route exact path='/roster/list'
          component={requireAuth(RosterListing, requirePermission(true, 'ROSTER'), requireFeature(true, "ROSTER"))} />

        <Route exact path='/roster/create'
          component={requireAuth(CreateRoster, requirePermission(true, 'ROSTER'), requireFeature(true, "ROSTER"))} />
        <Route exact path='/roster/update/:id'
          component={requireAuth(UpdateRoster, requirePermission(true, 'ROSTER'), requireFeature(true, "ROSTER"))} />
        <Route exact path='/rosters/:reports'
          component={requireAuth(PatrollingReportsList, requirePermission(true, 'ROSTER'), requireFeature(true, "ROSTER"))} />

        <Route exact path='/visualization/violations' component={requireAuth(ViolationBarChart, false, requireFeature(true, "VIOLATION_VISUALIZATION"))} />
        <Route exact path='/visualization/trips' component={requireAuth(TripsBarChart, false, requireFeature(true, "VIOLATION_VISUALIZATION"))} />
        <Route exact path='/visualization/gdthings' component={requireAuth(GdThingBarChart, false, requireFeature(true, "VIOLATION_VISUALIZATION"))} />
        <Route exact path='/visualization/fuel' component={requireAuth(FuelBarChart, false, requireFeature(true, "FUEL_VISUALIZATION"))} />
        <Route exact path='/visualization/fuelnew' component={requireAuth(FuelBarChartNew, false, requireFeature(true, "FUEL_VISUALIZATION"))} />
        <Route exact path='/download/reports' component={requireAuth(DownloadReports,false,requireFeature(true, "REPORTS"))} />
        <Route exact path='/video/playback' component={requireAuth(PlayBack, false, requireFeature(true, "MVDR_VIDEO"))}  />
        <Route exact path='/video/live' component={requireAuth(Live, false, requireFeature(true, "MVDR_VIDEO"))}  />



      {/* TRIKES */}

      <Route exact path='/trikes/summary' component={requireAuth(TrikesSummary, false)} />
      <Route exact path='/trikes/playback' component={requireAuth(TrikesPlayback, false)} />

       {/* TRIKES */}




      </ErrorBoundary>
    </Layout>

  </Switch>
);


// TODO: Fix vendor after its updated from react-select-nested-group
