import React from "react";
import { compose } from "recompose";

export const ReportsListing = compose()((props) => {
  
  return (
    <ul className="reports-list">
      {props.companyId === "664f4f93e440b860d2c1c93c" && (
        <li>
          <span>
            <h4>Mileage Report</h4>
            <p>
              This is Mileage Report for vehicles in Style Textiles.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("mileageReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehicleHistoryReport") !== -1 && (
        <li>
          <span>
            <h4>Activity Report</h4>
            <p> Summary of the Actvity of Vehicles</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("activityReport")}>select</a>
          </span>
        </li>
      )}
       {props.reports.indexOf("alarmReport") !== -1 && (
        <li>
          <span>
            <h4>Alarm Report</h4>
            <p>
              Summary of the alarm notifications received by the user, including
              the alarm name, unit name, alarm type and alarm contents, etc
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("alarmReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("tripDetailReport") !== -1 && (
        <li>
          <span>
            <h4>Trip Detail Report</h4>
            <p>
              Trip details of units in a interval, such as Duration, Trip
              Mileage, Location Start, Cumulative Mileage and Location End etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("tripReport")}>select</a>
          </span>
        </li>
        
      )}
       {props.reports.indexOf("tripDetailReport") !== -1 && props.companyId === "66fd17422e6f872ff9a43886" && (
        <li>
          <span>
            <h4>Trip Detail Cumulative</h4>
            <p>
              Cumulative trip report
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("tripReportCummulative")}>select</a>
          </span>
        </li>
        
      )}
      {props.reports.indexOf("idleReport") !== -1 && (
        <li>
          <span>
            <h4>Idle Report</h4>
            <p>
              Idle details of vehicles in an interval.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("idleReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("geofenceTrackReport") !== -1 && (
        <li>
          <span>
            <h4>Geofence Track Report</h4>
            <p>
              Trip report between a start and end geofenced location including
              driver details, voilations and total idle time in trip
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("geofenceTrackReport")}>
              select
            </a>
          </span>
        </li>
      )}
      {props.reports.indexOf("fuelConsumedReport") !== -1 && (
        <li>
          <span>
            <h4>Fuel Consumption Report</h4>
            <p>
              Fuel consumption along with average fuel with respect to every
              trip in the selected period.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("fuelAverage")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("distanceTravelledReport") !== -1 && (
        <li>
          <span>
            <h4>Distance Traveled Report</h4>
            <p>Total distance traveled in a time duration.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("distanceTraveled")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("violationReport") !== -1 && (
        <li>
          <span>
            <h4>Violation Report</h4>
            <p>
              The report is about the violation records of units in a interval,
              such as violation Time, violation Text, Location etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("eventReport")}>select</a>{" "}
          </span>
        </li>
      )}
      {props.reports.indexOf("violationsGraph") !== -1 && (
        <li>
          <span>
            <h4>Violation Report Graph</h4>
            <p>
              The report is about the violation records of units in a interval,
              such as violation Time, violation Text, Location etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("eventGraphReport")}>select</a>{" "}
          </span>
        </li>
      )}
      {props.reports.indexOf("violationReportBATCustom") !== -1 && (
        <li>
          <span>
            <h4>Violation Report Custom</h4>
            <p>
              The report is about the violation records of units in a interval,
              such as violation Time, violation Text, Location etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("violationReportBATCustom")}>
              select
            </a>{" "}
          </span>
        </li>
      )}

      {props.reports.indexOf("geofenceReport") !== -1 && (
        <li>
          <span>
            <h4>Geofence Report</h4>
            <p>
              Geofences report in an interval, such as Geofence properties, Time
              in, Time out.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("geofenceReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("geofenceSummaryReport") !== -1 && (
        <li>
          <span>
            <h4>Geofence Summary Report</h4>
            <p>
              Geofences Summary report in an interval, such as Geofence properties, Time
              in, Time out.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("geofenceSummaryReport")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "62f3be2fad2b772bb8cff1a1" && (
        <li>
          <span>
            <h4>Fuel Summary Report</h4>
            <p>Fuel summary of Consumption and Refill </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("newfuelAverage")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "62f3be2fad2b772bb8cff1a1" && (
       <li>
          <span>
            <h4>Fuel Activity Report</h4>
            <p>Fuel Activity of Consumption and Refill </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("newfuelActivity")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("geoFenceTourReport") !== -1 && (
        <li>
          <span>
            <h4>Geofence Tour Report</h4>
            <p>
              Geofences report in an interval, such as Geofence properties, Time
              in, Time out, Duration in, Mileage etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("geoFenceTourReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("routefenceReport") !== -1 && (
        <li>
          <span>
            <h4>Routefence Report</h4>
            <p>
              Routefences report in a interval, such as Routefence properties,
              Time in, Time out, Duration in, Mileage and Max Speed etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("routefenceReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehicleNotRespondingReport") !== -1 && (
        <li>
          <span>
            <h4>Not Responding Report</h4>
            <p>Summary of the devices which are not responding.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("NRReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("performance") !== -1 && (
        <li>
          <span>
            <h4>Performance Report</h4>
            <p>Performance Report</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("performanceReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehiclesStatusReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicles Status Report</h4>
            <p>Summary of the vehicle last observed status.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehiclesStatus")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehicleTravelReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicle Travel Report</h4>
            <p>
              Summary of the vehicle travel during a specified interval of time
              based on distance limit criteria.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicleTravelReport")}>
              select
            </a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehicleTravelReportGraph") !== -1 && (
        <li>
          <span>
            <h4>Vehicle Travel Graph Report</h4>
            <p>
              Summary of the vehicle travel during a specified interval of time
              based on distance limit criteria.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicleTravelReportGraph")}>
              select
            </a>
          </span>
        </li>
      )}
      {props.reports.indexOf("overSpeedingVehiclesReport") !== -1 && (
        <li>
          <span>
            <h4>OverSpeeding Vehicles Report</h4>
            <p>
              Report is about the overspeed violation in an interval and with
              set speed limit, such as speed, location time etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("overSpeeding")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("drivingScoreReport") !== -1 && (
        <li>
          <span>
            <h4>Driving Score Report</h4>
            <p>
              The report is about driving score based on each violation count in
              an interval.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("drivingScore")}>select</a>
          </span>
        </li>
      )}
       {props.reports.indexOf("VehicledrivingScoreReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicle Driving Score Report</h4>
            <p>
              The report is about Vehicle driving score based on each violation count in
              an interval.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicledrivingScore")}>select</a>
          </span>
        </li>
      )} 
      {props.reports.indexOf("drivingScoreGraph") !== -1 && (
        <li>
          <span>
            <h4>Driving Score Graph Report</h4>
            <p>
              The report is about driving score based on each violation count in
              an interval.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("drivingScoreGraph")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("stoppageReport") !== -1 && (
        <li>
          <span>
            <h4>Stoppage Report</h4>
            <p>
              Trip stoppage report gives information about vehicles stop and
              drivers duration in an interval, such as trip stop locations,
              duration, time etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("stopageReport")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "63989f8eba00314a1b750a18" && (
        <li>
          <span>
            <h4>Vehicle Tracking DLI Report</h4>
            <p>
              This report covers over all activity of vehicle, such as trip stop locations, duration, time etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("DLIReport")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "64ad5d565994fd70d6ed1523" && (
        <li>
          <span>
            <h4>Driver Behaviour Report</h4>
            <p>
              This is Driver Behaviour Report of Bikes in Engro.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("bycetrackingreport")}>select</a>
          </span>
        </li>
      )}
      {/* {props.companyId === "63d2891e720a9b5138d9cf2f" && (
        <li>
          <span>
            <h4>Vehicle Tracking Report</h4>
            <p>
              This report covers over all activity of vehicle, such as trip stop locations, duration, time etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicleReport")}>select</a>
          </span>
        </li>
      )} */}
      {props.companyId === "63d2891e720a9b5138d9cf2f" && (
        <li>
          <span>
            <h4>Vehicle Tracking Report</h4>
            <p>
              This report covers over all activity of vehicle, such as trip stop locations, duration, time etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicleReportTest")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("maintenance") !== -1 && (
        <li>
          <span>
            <h4>Oil Change Report</h4>
            <p>
              Oil change report provide insight about vehicle last oil change
              date, mileage, oil change due date, mileage, and reminder details
              about a single or all fleet vehicles.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("OIL_CHANGE")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("maintenance") !== -1 && (
        <li>
          <span>
            <h4>Tyre Change Report</h4>
            <p>
              Tyre change report provide insight about vehicle last tyre change
              date, mileage, tyre change due date, mileage, and reminder details
              about a single or all fleet vehicles.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("TYRE_CHANGE")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("maintenance") !== -1 && (
        <li>
          <span>
            <h4>Other Maintainance Report</h4>
            <p>Other Maintainance.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("otherMaintainance")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("geofenceReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicles Geofence Report</h4>
            <p>Vehicles geofences.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehiclesGeoFenceReport")}>
              select
            </a>
          </span>
        </li>
      )}
      {props.reports.indexOf("routefenceReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicles Routefence Report</h4>
            <p>Vehicles routefences.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehiclesRouteFenceReport")}>
              select
            </a>
          </span>
        </li>
      )}
      {props.features.indexOf("HOTSPOT") !== -1 && (
        <li>
          <span>
            <h4>PJP Report</h4>
            <p>PJP Report.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("pjpReport")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "603f7be06a23817d9e06a2f4" && (
        <li>
          <span>
            <h4>Fuel Report</h4>
            <p>Fuel Report.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("geoFenceFuelReport")}>select</a>
          </span>
        </li>
      )}
      {props.companyId === "60efe1d1754fed771258f000" && (
        <li>
          <span>
            <h4>Helmet Status Report</h4>
            <p>Helmet Status Report.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("helmetStatusReport")}>select</a>
          </span>
        </li>
      )}
      {props.reports.indexOf("vehicleHistoryReport") !== -1 && (
        <li>
          <span>
            <h4>Vehicle History Report</h4>
            <p>Vehicle Observation History.</p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("vehicleHistoryReport")}>
              select
            </a>
          </span>
        </li>
      )}
{/* {props.reports.indexOf("TripMilageReport") !== -1 && ( */}
        {/* <li>
          <span>
            <h4>Trip Milage Report</h4>
            <p>
              Trip details of units in a interval, such as Duration, Trip
              Mileage, Location Start, Cumulative Mileage and Location End etc.
            </p>
          </span>
          <span className="select-report">
            <a onClick={() => props.selectType("tripMilageReport")}>select</a>
          </span>
        </li> */}
             {/* )} */}
    </ul>
  );
});
