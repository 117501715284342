import * as turf from "@turf/turf";

/**
 * Checks if a vehicle's path fully covers a route fence.
 *
 * @param {Array} routeFences - The route fence path in [lng, lat] format.
 * @param {Array} vehiclePath - The vehicle path in {lat, lng} format.
 * @returns {string} - "Productive" if fully covered, "Non-Productive" otherwise.
 */


export const checkRouteCoverage = (routeFences, vehiclePath) => {
  if (!routeFences || !vehiclePath || routeFences.length === 0 || vehiclePath.length === 0) {
    return "Non-Productive";
  }

  const routeFenceLine = turf.lineString(
    routeFences.map(coord => [coord[0], coord[1]])
  );
  const vehiclePathLine = turf.lineString(
    vehiclePath.map(coord => [coord.lng, coord.lat])
  );

  const intersects = turf.lineIntersect(routeFenceLine, vehiclePathLine);

  if (intersects.features.length === 0) {
    return "Non-Productive";
  }

  const totalRouteLength = turf.length(routeFenceLine);
  const coveredSegments = turf.lineSplit(routeFenceLine, vehiclePathLine);
  let coveredLength = 0;

  coveredSegments.features.forEach(segment => {
    const segmentLine = turf.lineString(segment.geometry.coordinates);
    const intersection = turf.lineIntersect(segmentLine, vehiclePathLine);
    if (intersection.features.length > 0) {
      coveredLength += turf.length(segmentLine);
    }
  });

  const coverageThreshold = 0.99;
  return coveredLength / totalRouteLength >= coverageThreshold
    ? "Productive"
    : "Non-Productive";
};
