import React, { useEffect } from "react";
import Select from "react-select-nested-group";
import SelectComponent from "../select";
import { Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import calenderIcon from '../../assets/img/calender.png';
import './index.css'
import { subDays } from 'date-fns';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { map } from "lodash";
import ragscore from "../../ducks/ragscore";
import { Spinner } from '../common/Spinner/index';
import PaginatedTable from "../common/PaginatedTable";
import MapModal from "../common/maps/MapModal";


const CardinalViolations = (props) => {
  let columns = [
    { name: 'Group Name', selector: "groupName", wrap: true, minWidth: "200px" },
    { name: 'Vehicle Plate Number', selector: "plateNumber", wrap: true, grow: 1, minWidth: "150px" },
    { name: 'Driver Name', selector: "driverName", wrap: true, grow: 1, minWidth: "150px" },
    { name: 'Driver Serial Number', selector: "driverSerialNumber", wrap: true, grow: 1, minWidth: "150px" },
    { name: 'IMEI', selector: "imei", wrap: true, minWidth: "150px" },
    { name: 'Type Of Violation', selector: "eventType", wrap: true, grow: 1, minWidth: "150px" },
    { name: 'Date and Year of Violation', selector: "date", wrap: true, minWidth: "150px" },
    { name: 'Time of violation', selector: "time", wrap: true, minWidth: "150px" },
    {
      name: 'Location of Vehicle', selector: "location", wrap: true, grow: 1, minWidth: "450px", cell: row => (
        <p onClick={() => {
          let latlng = row.latlng
          let [lat, lng] = latlng.split(',').map(parseFloat);
          setLatlng({
            lat,
            lng
          })
        }} className="tripLocation">
          {row.location}
        </p>
      ),
    },
    { name: 'Speed at which violation observed', selector: "speedValue", wrap: true, minWidth: "230px" },
    { name: 'Action Plan', selector: "actionPlan", wrap: true, grow: 1, minWidth: "250px" },
  ];
  let headers = [
    { label: 'Group Name', key: "groupName" },
    { label: 'Vehicle Plate Number', key: "plateNumber" },
    { label: 'Driver Name', key: "driverName" },
    { label: 'Driver Serial Number', key: "driverSerialNumber" },
    { label: 'IMEI', key: "imei" },
    { label: 'Type Of Violation', key: "eventType" },
    { label: 'Date and Year of Violation', key: "date" },
    { label: 'Time of violation', key: "time" },
    { label: 'Location of Vehicle', key: "location" },
    { label: 'Speed at which violation observed', key: "speedValue" },
    { label: 'Action Plan', key: "actionPlan" },
  ];


  const [popoverOpen, setPopoverOpen] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState([])
  const [showModal, setShowModal] = React.useState(false)
  const [selectedGroup, setSelectedGroup] = React.useState([])
  const [coordinates, setLatlng] = React.useState(null);
  const [searchVehicleValues, setSearchVehicleValues] = React.useState([])
  const [dateRangePicker, setDateRange] = React.useState({
    selection: {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: 'selection',
    },
  },)

  const handleRangeChange = async (payload) => {
    setDateRange({ ...payload })
  }
  const handleModalClose = () => {
    setShowModal(!showModal)
  }
  
  useEffect(()=>{
    if(coordinates !== null){
      setShowModal(!showModal)
    }
  },[coordinates])

  const toggle = () => {
    setPopoverOpen(!popoverOpen)
  };
  const filterVehicle = async (value) => {
    setSearchValue({ ...value });
  };

  const fetchOptions = async () => {
    await props.ragscoreAction.getThings();
    await props.ragscoreAction.getSubGroups();
  }

  const selectGroup = (selectedGroup) => {
    setSelectedGroup(selectedGroup)
  }

  React.useEffect(() => {
    if (searchValue?.value) {
      setSelectedGroup([])
    }
  }, [searchValue]);

  React.useEffect(() => {
    if (selectedGroup?.length > 0) {
      setSearchValue([])
    }
  }, [selectedGroup]);
  React.useEffect(() => {
    setSearchVehicleValues(map(props?.things, (item) => ({
      value: item._id,
      label: item.plateNumber || item.imei.substr(-8),
      item: item,
    })))
  }, [props.things]);
  const fetchCardinalViolations = async () => {
    if (searchValue.value) {
      await props.ragscoreAction.getCardinalViolations(searchValue.value, dateRangePicker)
    }
    else if (selectedGroup.length > 0) {
      await props.ragscoreAction.getCardinalViolations(selectedGroup, dateRangePicker)
    }
  }
  React.useEffect(() => {
    fetchOptions()
  }, []);
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <Select
            name="form-field-name"
            isSearchable={true}
            placeholder="Search Vehicle"
            onChange={filterVehicle}
            value={searchValue}
            options={searchVehicleValues}
          />
        </div>
        <div className="col-md-3">
          <SelectComponent
            field="form-field-name"
            placeholder="Select Group"
            preSelected={selectedGroup}
            multi={true}
            optionKeys={{ label: "name", value: "_id" }}
            options={props.groupList}
            onChange={selectGroup}
          />
        </div>
        <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <div className="float-right">
            <a onClick={toggle} id="Popover1">
              <img className="rag-picker" src={calenderIcon} alt="" />
            </a>
            <Popover placement="bottom" isOpen={popoverOpen} toggle={toggle} target="Popover1">
              <PopoverBody>
                <div>
                  <DateRangePicker
                    onChange={handleRangeChange}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    className={'PreviewArea'}
                    months={1}
                    ranges={[dateRangePicker.selection]}
                    direction="horizontal"
                    maxDate={new Date()}
                  />
                </div>
              </PopoverBody>
            </Popover>
            <button onClick={fetchCardinalViolations} className='btn rag-primary-btn rag-score-filter-btn' >Filter
            </button>
          </div>
        </div>
      </div>
      {props.isLoading === true && (
        <Spinner />
      )}
      {searchValue.length === 0 && selectedGroup.length === 0 && (
        <div className="vehicle-plate-Number-model">
          <p className="vehicle-plate-number">No Vehicle Selected</p>
          <p className="vehicle-model">{'N/A'}</p>
        </div>
      )}

      {props.isLoading === false && (
        <>
          <MapModal showMap={showModal} close={handleModalClose} lat={coordinates?.lat} lng={coordinates?.lng} />
          <PaginatedTable
            columns={columns}
            data={props.cardinalViolations === null ? [] : props.cardinalViolations}
            headers={headers}
            title="Cardinal Violations"
            fileName="Cardinal_Violations" />
        </>
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    things: state.ragscore.things,
    groupList: state.ragscore.groupList,
    isLoading: state.ragscore.isLoading,
    cardinalViolations: state.ragscore.cardinalViolations
  };
};
const mapDispatchToProps = (dispatch) => ({
  ragscoreAction: bindActionCreators(ragscore.creators, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CardinalViolations)
